@font-face {
  font-family: 'C64_Pro_Mono-STYLE';
  font-style:  normal;
  font-display: auto;
  font-weight: 200;
  src: url("../fonts/C64_Pro_Mono-STYLE.woff") format("woff");
}

@font-face {
  font-family: 'C64_Pro_Mono-STYLE';
  font-style:  normal;
  font-display: auto;
  font-weight: 400;
  src: url("../fonts/C64_Pro_Mono-STYLE.woff") format("woff");
}

